import React,{ useState,useEffect } from "react";
import { Helmet } from 'react-helmet-async';
// @mui
import {
    Popover,
    Card,
    Table,
    TableRow,
    TableHead,
    MenuItem,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    Button,
    Container, 
    Stack, 
    Typography, 
    Modal, 
    TextField, 
    InputAdornment, 
    InputLabel ,
    FormControl ,
    Select ,
    IconButton, 
    Divider, 
    Snackbar,
    Toolbar,
    OutlinedInput,
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
// components
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
import { supabaseClient } from 'src/supabase';
import { useNavigate,useSearchParams} from 'react-router-dom';
import { styled, alpha } from '@mui/material/styles';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {fDate} from '../utils/formatTime'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from "dayjs";
import 'dayjs/locale/en-gb';
//----------------------------------------------------------------------
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
// ----------------------------------------------------------------------

const StyledRoot = styled(Toolbar)(({ theme }) => ({
    height: 96,
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 1, 0, 3),
  }));
  
const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
  width: "100%",
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': {
    width: "100%",
    boxShadow: theme.customShadows.z8,
  },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
  },
}));
  
// ----------------------------------------------------------------------


export default function Stock (){
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const [open, setOpen] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [name,setName] = useState('');
    const [companies,setCompanies] = useState([]);
    const [fournisseurs,setFournisseurs] = useState([]);

    const handleOpen = () => setOpenModal(true);
    const handleClose = () => {
        
        setOpenModal(false);
    }

    const [openBar, setOpenBar] = useState(false);
    const [alertMessage,setAlertMessage] = useState('');

    const handleOpenBar = (message)=>{
        setAlertMessage(message)
        setOpenBar(true)
    }

    const handleCloseBar = (event, reason) => {
      setOpenBar(false);
    };

    const handleCloseMenu = () => {
        setOpen(null);
    };

    

    const handleAdd = async () => {

        await supabaseClient.from('Stock').insert(arrivage)
        const {data,error} = await supabaseClient.from("Produits").select("*,Fournisseur(name),Stock(*)").eq('id',productID).order('created_at',{ascending:false})
        let dt = []
        let rt = []
        let st = []
            data[0].Stock.forEach((value)=>{
                if (value.owner === 'depot'){
                    dt.push(value)
                }
                if (value.owner === 'Tissus Home' || value.owner === 'Tissu Home' ){
                    rt.push(value)
                }
                if (value.owner === 'Aïn Sebaâ'){
                    st.push(value)
                }
            })
            setStockDepot(dt)
            setStockTH(rt)
            setStockAS(st)
        handleClose()
        handleOpenBar(`Un Produit a été ajouté!\nRéference: ${name}.`)
        setName('')
        setNewProdSup('')
        setArrivage([])
        
    };
    const [productID,setProductID] = useState(null)
    const [stockDepot,setStockDepot] = useState([])
    const [stockTH,setStockTH] = useState([])
    const [stockAS,setStockAS] = useState([])
    const [stockRA,setStockRA] = useState([])

    useEffect(()=>{
        let prod = searchParams.get('id')
        if (prod === null){
            navigate('/dashboard/products')
        }else{
            setProductID(prod)
        }
        const fn = async ()=>{
            const resp = await supabaseClient.auth.getSession()
            console.log(resp)
            if (resp.data.session === null){
                navigate("/login",{replace:true})
            }
            const {data:access,error:er} = await supabaseClient.from("Admins").select("*")
            if (access.length === 0 || er){
                navigate('/403',{replace:true})
                console.log("Access",access)
                
            }
            const {data,error} = await supabaseClient.from("Produits").select("*,Fournisseur(name),Stock(*)").eq('id',prod).order('created_at',{ascending:false})
            let dt = []
            let rt = []
            let st = []
            let dt2 = []
            data[0].Stock.forEach((value)=>{
                if (value.owner === 'depot'){
                    dt.push(value)
                }
                if (value.owner === 'Tissus Home' || value.owner === 'Tissu Home' ){
                    rt.push(value)
                }
                if (value.owner === 'Aïn Sebaâ'){
                    st.push(value)
                }
                if(value.owner ==="Rue D'Agadir"){
                    dt2.push(value)
                }
            })
            setStockDepot(dt)
            setStockTH(rt)
            setStockAS(st)
            setStockRA(dt2)
            console.error(error)
            console.log(data)
            setCompanies(data)
            
        }
        fn()
    },[])

    
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - companies.length) : 0;
    const [currentID,setCurrentID] = useState('');
    const [newProdSup,setNewProdSup] = useState('');
    const [serachName,setSerachName] = useState('')
    const [openModalDelete, setOpenModalDelete] = useState(false);
    const  handleNewProdSup = (event)=>{
        setNewProdSup(event.target.value)
    }
    const handleOpenDelete = () => setOpenModalDelete(true);
    const handleCloseDelete = () => setOpenModalDelete(false);


    const handleOpenMenu = (event,id) => {
        setCurrentID(id)
        setOpen(event.currentTarget);
    };

    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleDelete = async ()=>{
        await supabaseClient.from("Stock").delete().eq('id',currentID)
        const {data,error} = await supabaseClient.from("Produits").select("*,Fournisseur(name),Stock(*)").eq('id',productID).order('created_at',{ascending:false})
            let dt = []
            let rt = []
            data[0].Stock.forEach((value)=>{
                if (value.owner === 'depot'){
                    dt.push(value)
                }else{
                    rt.push(value)
                }
            })
            setStockDepot(dt)
            setStockTH(rt)
        handleCloseMenu()
        handleCloseDelete()
        setCompanies(data)
        console.log({data,error})
        handleOpenBar(`Le Rouleau a bien été supprimer.`)
    }

    const [arrivage,setArrivage] = useState([])
    const [roulNbr,setRoulNbr] = useState(0)
    const handleAddRouleau = () => {
        console.log('Rllll',roulNbr)
        setRoulNbr(roulNbr + 1)
        setArrivage([...arrivage,{product:productID,owner:'',quant:0}])

    }

    const handleQuant = (quant,index)=>{
        let cparr = [...arrivage]
        cparr[index].quant = parseFloat(quant)
        cparr[index].owner = owner
        cparr[index].created_at = dateDev.toISOString()
        setArrivage(cparr)
    }

    const hadnleDeleteQuant = (index)=>{
        let cparr = [...arrivage]
        cparr.splice(index,1)
        setRoulNbr(roulNbr - 1)
        setArrivage(cparr)
    }

    const [owner,setOwner] = useState('depot')
    const [dateDev,setDateDev] = useState(dayjs(new Date()))
    const handleOwnerChange = (event,target)=>{
        setOwner(target)
    }
    
    return (
        <>
        <Helmet>
            <title> Votre Inventaire </title>
        </Helmet>
        <Snackbar open={openBar} anchorOrigin={{ vertical:"top", horizontal:'center' }} autoHideDuration={6000} onClose={handleCloseBar} onClick={handleClose}>
            <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                {alertMessage}
            </Alert>
        </Snackbar>
        <Modal
            open={openModal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{alignItems:"center",justifyContent:'center'}}
        >
            <Container 
                sx={{
                    maxWidth:"80%",
                    maxHeight:"80%",
                    overflow:"scroll",
                    alignSelf:'center',
                    borderColor:'#103996',
                    borderRadius:"10px",
                    backgroundColor:'#fff',
                    padding:5,
                    justifySelf:'center',
                    marginTop:"5%"}}
            >
                <Scrollbar >
                <Stack direction="column" alignItems="center" justifyContent="space-evenly" alignContent='center' m={1} spacing={2}>
                    <Typography variant="h6" alignContent='center'>
                        Nouveau Arrivage : {companies[0]?companies[0].reference.toUpperCase():''}
                    </Typography>
                    <Divider sx={{ borderStyle: 'dashed' }} />
                    <ToggleButtonGroup
                        style={{justifySelf:'center',display:'block'}}
                        color="primary"
                        value={owner}
                        exclusive
                        onChange={handleOwnerChange}
                        aria-label="Search Option"
                        disabled={arrivage.length > 0}
                        >
                        <ToggleButton value="depot">Dépot</ToggleButton>
                        {/*<ToggleButton value="Tissus Home">Tissus Home</ToggleButton>
                        <ToggleButton value="Aïn Sebaâ">Aïn Sebaâ</ToggleButton>
                        <ToggleButton value="Rue D'Agadir">Rue D'Agadir</ToggleButton>*/}
                    </ToggleButtonGroup>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                        <DatePicker minWidth={250} label="Date d'Ajout" disabled={arrivage.length > 0} defaultValue={dateDev} onChange={((value)=>{setDateDev(value)})}/>
                    </LocalizationProvider>
                    {arrivage.map((val,index)=>{
                        return (
                            <Stack direction="row"  justifyContent="space-evenly" minWidth={250} spacing={1}>
                                <TextField sx={{width:'50%',minWidth:250}} value={(val.quant === 0)?"":val.quant} InputProps={{type:'number'}} name={`Rouleau ${index + 1}`} label={`Rouleau ${index + 1}`} onChange={(e)=>handleQuant(e.target.value,index)}/>
                                <IconButton size="large" sx={{ color: 'error.main' }} onClick={()=>{hadnleDeleteQuant(index)}}>
                                    <Iconify icon={'eva:trash-2-outline'} />
                                </IconButton>
                            </Stack>
                        )
                    })}
                    
                    <IconButton size="large" sx={{ color: 'success.main' ,margin:4}} onClick={handleAddRouleau}>
                        <Iconify icon={'eva:plus-circle-outline'} />
                    </IconButton>
                    
                    <Button 
                        variant="contained" 
                        onClick={handleAdd} 
                        mt={4} 
                        disabled={ arrivage.length === 0}
                    >
                    Ajouter 
                    </Button>
                </Stack>
                </Scrollbar>
            </Container>
        </Modal>
        <Modal
            open={openModalDelete}
            onClose={handleCloseDelete}
            sx={{alignItems:"center",justifyContent:'center'}}
        >
            <Container 
                sx={{
                    alignSelf:'center',
                    borderColor:'#103996',
                    borderRadius:"10px",
                    backgroundColor:'#fff',
                    padding:5,
                    justifySelf:'center',
                    marginTop:"5%"}}
            >
                <Scrollbar >
                <Stack direction="column" alignItems="center" justifyContent="space-evenly" alignContent='center' m={3} spacing={3}>
                    <Typography variant="h6"  alignContent='center' textOverflow='revert'>
                        Voulez Vous Supprimer ce Rouleau ?
                    </Typography>
                    
                    <Divider sx={{ borderStyle: 'dashed' }} />
                    <Typography variant="h8"  alignContent='center' color="text.secondary" textAlign='center'>
                    <strong> Veuillez noter que les informations associées seront perdu et irrecuperable !</strong>
                    </Typography>
                    <Stack direction="row" alignItems="center" justifyContent="space-evenly" alignContent='center' m={3} spacing={3}>
                        <Button
                            variant="contained"
                            startIcon={<DeleteForeverIcon />}
                            onClick={handleDelete}
                        >
                            OUI 
                        </Button>
                        <Button
                            variant="contained"
                            onClick={handleCloseDelete}
                        >
                            NON
                        </Button>
                    </Stack>
                </Stack>
                </Scrollbar>
            </Container>
        </Modal>
        
        <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom >
            {companies[0]?companies[0].reference.toUpperCase():''}
        </Typography>
        <Button variant="contained" onClick={handleOpen} startIcon={<Iconify icon="eva:plus-fill" />}>
            Nouveau Arrivage 
        </Button>
        </Stack>
        <Card>
            
            <Stack direction="row" width='100%' alignItems="center" justifyContent="space-evenly" m={3} >
                <Typography variant="h6" gutterBottom>
                        Les Rouleaux Dépot
                </Typography>
            </Stack>
            
            <Scrollbar>
                    <TableContainer >
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align = 'center'>
                                    Date d'Ajout
                                </TableCell>
                                <TableCell align = 'center'>
                                    Quantité (Réservée)
                                </TableCell>
                                <TableCell align = 'center'>
                                    {' '}
                                </TableCell>
                                
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {stockDepot.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                            const {created_at,quant ,id} = row;
                            
                            return (
                            <TableRow hover onClick={()=>{console.log("rowClicked",id)}} key={id} tabIndex={-1}  >
                                <TableCell align="center">{fDate(created_at)}</TableCell>
                                <TableCell align="center">{`${quant} m(s)`}</TableCell>

                                <TableCell align="center">
                                    <IconButton size="large" sx={{ color: 'error.main' }} onClick={()=>{
                                        setCurrentID(id)
                                        handleOpenDelete()}}>
                                        <Iconify icon={'eva:trash-2-outline'} />
                                    </IconButton>
                                </TableCell>
                                
                            </TableRow>
                            );
                        })}
                        {emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={6} />
                            </TableRow>
                        )}
                        </TableBody>

                        
                    </Table>
                    </TableContainer>
                </Scrollbar>

            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={companies.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Card>
        <br/>
        
        {/*<Card>
            
            <Stack direction="row" width='100%' alignItems="center" justifyContent="space-evenly" m={3} >
                <Typography variant="h6" gutterBottom>
                        Les Rouleaux Tissus Home
                </Typography>
            </Stack>
            
            <Scrollbar>
                    <TableContainer >
                    <Table>
                        <TableHead>
                            <TableRow>
                                
                                
                                <TableCell align = 'center'>
                                    Date d'Ajout
                                </TableCell>
                                <TableCell align = 'center'>
                                    Quantité (Réservée)
                                </TableCell>
                                <TableCell align = 'center'>
                                    {' '}
                                </TableCell>
                                
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {stockTH.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                            const {created_at,quant ,id} = row;
                            
                            return (
                            <TableRow hover onClick={()=>{console.log("rowClicked",id)}} key={id} tabIndex={-1}  >
                                <TableCell align="center">{fDate(created_at)}</TableCell>
                                <TableCell align="center">{`${quant} m(s)`}</TableCell>

                                <TableCell align="center">
                                    <IconButton size="large" sx={{ color: 'error.main' }} onClick={()=>{
                                        setCurrentID(id)
                                        handleOpenDelete()}}>
                                        <Iconify icon={'eva:trash-2-outline'} />
                                    </IconButton>
                                </TableCell>
                                
                            </TableRow>
                            );
                        })}
                        {emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={6} />
                            </TableRow>
                        )}
                        </TableBody>

                        
                    </Table>
                    </TableContainer>
                </Scrollbar>

            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={companies.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Card>
        <br/>
        
        <Card>
            
            <Stack direction="row" width='100%' alignItems="center" justifyContent="space-evenly" m={3} >
                <Typography variant="h6" gutterBottom>
                        Les Rouleaux Aïn Sebaâ
                </Typography>
            </Stack>
            
            <Scrollbar>
                    <TableContainer >
                    <Table>
                        <TableHead>
                            <TableRow>
                                
                                
                                <TableCell align = 'center'>
                                    Date d'Ajout
                                </TableCell>
                                <TableCell align = 'center'>
                                    Quantité (Réservée)
                                </TableCell>
                                <TableCell align = 'center'>
                                    {' '}
                                </TableCell>
                                
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {stockAS.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                            const {created_at,quant ,id} = row;
                            
                            return (
                            <TableRow hover onClick={()=>{console.log("rowClicked",id)}} key={id} tabIndex={-1}  >
                                <TableCell align="center">{fDate(created_at)}</TableCell>
                                <TableCell align="center">{`${quant} m(s)`}</TableCell>

                                <TableCell align="center">
                                    <IconButton size="large" sx={{ color: 'error.main' }} onClick={()=>{
                                        setCurrentID(id)
                                        handleOpenDelete()}}>
                                        <Iconify icon={'eva:trash-2-outline'} />
                                    </IconButton>
                                </TableCell>
                                
                            </TableRow>
                            );
                        })}
                        {emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={6} />
                            </TableRow>
                        )}
                        </TableBody>

                        
                    </Table>
                    </TableContainer>
                </Scrollbar>

            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={companies.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Card>
        <br/>
        <Card>
            
            <Stack direction="row" width='100%' alignItems="center" justifyContent="space-evenly" m={3} >
                <Typography variant="h6" gutterBottom>
                        Les Rouleaux Rue D'Agadir
                </Typography>
            </Stack>
            
            <Scrollbar>
                    <TableContainer >
                    <Table>
                        <TableHead>
                            <TableRow>
                                
                                
                                <TableCell align = 'center'>
                                    Date d'Ajout
                                </TableCell>
                                <TableCell align = 'center'>
                                    Quantité (Réservée)
                                </TableCell>
                                <TableCell align = 'center'>
                                    {' '}
                                </TableCell>
                                
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {stockRA.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                            const {created_at,quant ,id} = row;
                            
                            return (
                            <TableRow hover onClick={()=>{console.log("rowClicked",id)}} key={id} tabIndex={-1}  >
                                <TableCell align="center">{fDate(created_at)}</TableCell>
                                <TableCell align="center">{`${quant} m(s)`}</TableCell>

                                <TableCell align="center">
                                    <IconButton size="large" sx={{ color: 'error.main' }} onClick={()=>{
                                        setCurrentID(id)
                                        handleOpenDelete()}}>
                                        <Iconify icon={'eva:trash-2-outline'} />
                                    </IconButton>
                                </TableCell>
                                
                            </TableRow>
                            );
                        })}
                        {emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={6} />
                            </TableRow>
                        )}
                        </TableBody>

                        
                    </Table>
                    </TableContainer>
                </Scrollbar>

            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={companies.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Card>*/}

    </Container>
       
        </>
    );



}